<template>
    <div class="update-container">
        <div>
            <div class="font">修改登录密码</div>
            <el-form :model="passwordForm" :rules="passwordRule" ref="passwordForm" label-width="100px">
                <el-form-item label="旧密码" prop="old_password">
                    <el-input type="password" show-password v-model="passwordForm.old_password"
                        placeholder="请输入旧密码"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="new_password">
                    <el-input type="password" v-model="passwordForm.new_password" show-password placeholder="请输入新密码">
                    </el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="confirm_password">
                    <el-input v-model="passwordForm.confirm_password" show-password placeholder="请输入确认密码">
                    </el-input>
                </el-form-item>
            </el-form>
            <div style="text-align: center;">
                <el-button type="primary" :disabled="payVisible" @click="confirm">确定</el-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "updatePassword",
    data() {
        return {
            payVisible: false,
            passwordForm: {
                old_password: '',
                new_password: '',
                confirm_password: ''
            },
            passwordRule: {
                old_password: [{ required: true, message: "请输入旧密码", trigger: "change" }],
                new_password: [{ required: true, message: "请输入新密码", trigger: "change" }],
                confirm_password: [{ required: true, message: "请输入确认密码", trigger: "change" }]
            },
        }
    },
    methods: {
        confirm() {
            this.$refs.passwordForm.validate((valid) => {
                if (valid) {
                    this.payVisible = true
                    if (this.passwordForm.new_password == this.passwordForm.confirm_password) {
                        this.$api.delivery.updatePayPassword(this.passwordForm).then(res => {
                            this.tool.message('密码已修改', 'success')
                            setTimeout(() => {
                                sessionStorage.setItem("userInfo", "");
                                sessionStorage.setItem("token", "");
                                sessionStorage.removeItem("windowid");
                                this.$message({
                                    message: "退出登录",
                                    type: "success",
                                });
                                this.$router.push({
                                    path: "/",
                                });
                            }, 100);
                            this.payVisible = false
                        }).catch(err => {
                            this.payVisible = false
                        })
                    } else {
                        this.payVisible = false
                        this.tool.message('密码与确认密码不一致', 'error')
                    }
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        }
    }
}
</script>

<style lang="scss">
.update-container {
    height: 100%;
    background-color: #ffffff;
    padding: 20px 20px 0;
    box-sizing: border-box;

    .font {
        text-align: center;
        margin-bottom: 20px;
        font-size: 18px;
    }
}
</style>